import { Box, Container, Divider, Link, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const Footer = () => {

  const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <Content>
      <ContainerCustom>
        <List>
          <Box
            component="img"
            sx={{
              width: isMobile ? '160px' : '240px',
              position: 'relative',
              left: '-5px'
            }}
            alt={'Deel Markets'}
            src={'assets/images/logo_footer.png'}
          />
          <Column>
            <Link id="legales" color={'secondary'} underline="none">Legales</Link>
            <Link id="terminos-y-condiciones" color={'secondary'} underline="none">Términos y condiciones</Link>
          </Column>
        </List>
        <List>
          <Link href='#Home' color={'secondary'} underline="none">Home</Link>
          <Link href='#Como-funciona' color={'secondary'} underline="none">Cómo funciona</Link>
          <Link href='#Tipo-de-cuenta' color={'secondary'} underline="none">Tipo de cuenta</Link>
          <Link href='#Beneficios' color={'secondary'} underline="none">Beneficios</Link>
          <Link href='#cTraders' color={'secondary'} underline="none">cTraders</Link>
        </List>
        <List>
          <Column>
            <Typography color={'secondary'}>Soporte</Typography>
            <Link>Support@deelmarkets.com</Link>
          </Column>
          <Column>
            <Typography color={'secondary'}>Administración</Typography>
            <Link>Admin@deelmarkets.com</Link>
          </Column>
          <Typography color={'secondary'}>Seguinos en <Link href="" underline="none" color={'secondary'}><Img height={"18px"} src='/assets/images/instagram.png' alt="Instagram de Deel Markets"/></Link></Typography>
        </List>
      </ContainerCustom>
      <Container>
        <Divider color='#64686D' />
        <br />
        <Typography color={'GrayText'} fontSize={14}>
          Advertencia de riesgo: Operar con derivados y productos apalancados conlleva un alto nivel de riesgo, incluido el riesgo de perder una cantidad sustancialmente mayor que la inversión inicial. No es adecuado para todos. Antes de tomar cualquier decisión en relación con un producto financiero, debe obtener y considerar nuestros términos y condiciones e información legal disponibles en nuestro sitio web y buscar asesoramiento independiente si es necesario.
        </Typography>
        <br />
        <Typography color={'GrayText'} fontSize={14}>
          La información que se incluye en este sitio web es de carácter general y no tiene en cuenta sus objetivos, situación financiera ni necesidades. Hacemos todo lo posible para proporcionar información precisa. Sin embargo, la información está sujeta a cambios en cualquier momento sin previo aviso. DeelMarkets no puede garantizar ni asume ninguna responsabilidad legal por la relevancia, precisión, actualidad o integridad de la información.
        </Typography>
        <br />
        <Typography color={'GrayText'} fontSize={14}>
          La información contenida en este sitio no está dirigida a residentes de ningún país o jurisdicción donde dicha distribución o uso sea contrario a las leyes o regulaciones locales.
        </Typography>
      </Container>
    </Content>
  )
}

export default Footer

const Content = styled.footer`
  background-color: #0B1119;
  padding: 2rem 0;
`

const ContainerCustom = styled(Container)`
  display: grid !important;
  gap: 18px;
  grid-template-columns: 1fr 1fr 1.5fr;
  padding-bottom: 2rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 0;
    padding-bottom: 0;
  }
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  height: 200px;
  @media (max-width: 768px) {
    justify-content: flex-start;
    padding-bottom: 2.5rem;
    height: auto;
  }
`

const Column = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`

const Img = styled.img`
  margin-left: 4px;
  position: relative;
  top: 4px;
` 