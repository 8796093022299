import { ThemeProvider } from '@mui/material';
import Navbar from './components/navbar/Navbar';
import { theme } from './theme/theme';
import Banner from './components/banner/Banner';
import HowItWorks from './components/howItWorks/HowItWorks';
import AccountType from './components/accountType/AccountType';
import WhyChooseUs from './components/whyChooseUs/WhyChooseUs';
import CTraders from './components/cTraders/CTraders';
import Footer from './components/footer/Footer';
import DesignBy from './components/designBy/DesignBy';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <Banner />
      <HowItWorks />
      <AccountType />
      <WhyChooseUs />
      <CTraders />
      <Footer />
      <DesignBy />
    </ThemeProvider>
  );
}

export default App;
