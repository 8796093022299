import { Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const Information = () => {
    const isMobile = useMediaQuery('(max-width:768px)');
    return (
        <Container>
            <Typography color={'secondary'} fontSize={isMobile ? 32 : 48} fontWeight={'bold'} >Elegí tu</Typography>
            <Typography color={'primary'} fontSize={isMobile ? 32 : 48} fontWeight={'bold'} lineHeight={isMobile ? .2 : .5}>tipo de cuenta</Typography>
            <br />
            <Typography color={'secondary'} fontSize={isMobile ? 18 : 28}>Nos comprometemos a ofrecer configuraciones y ejecuciones comerciales competitivas.</Typography>
        </Container>
    )
}

export default Information

const Container = styled.div`
    width: 364px;
    height: 496px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: auto;
        padding: auto;
        gap: 8px;
        padding: 1.5rem 0;
    }
`