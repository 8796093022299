import { Box, Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const CardMethod = (props) => {

    const { title, icon } = props

    return (
        <Content>
            <Box
                component="img"
                sx={{
                    height: 36,
                    width: 36,
                    // borderRadius: '50%',
                    // objectFit: 'cover',
                    // boxShadow: 3,
                }}
                alt={title}
                src={icon}
            />
            <Typography fontSize={16} fontWeight={'bold'} color={'info'}>{title}</Typography>
        </Content>
    )
}

export default CardMethod

const Content = styled.div`
    width: 180px;
    height: 66px;
    border-radius: 15px;
    background-color: #fff;
    padding: 0.5rem;
    display: flex;
    gap: 8px;
    align-items: center;
    @media (max-width: 768px) {
        width: calc(100% - 1rem);
        padding: auto;
        justify-content: center;
    }
`