import { Box, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import styled from 'styled-components';

const Card = (props) => {

    const { title, image, description } = props

    const isMobile = useMediaQuery('(max-width:768px)');

    return (
        <Content>
            <Box
                component="img"
                sx={{
                    height: isMobile ? 160 : 200,
                    width: 'auto',
                    position: 'relative',
                    top: '-20%'
                }}
                alt={title}
                src={image}
            />
            <Information>
                <Typography fontSize={isMobile ? 28 : 36} fontWeight={600} color={'info'}>{title}</Typography>
                <Typography textAlign={'center'} fontSize="16" color={'GrayText'}>{description}</Typography>
            </Information>
        </Content>
    )
}

export default Card

const Content = styled.div`
    width: calc(360px - 1.5rem);
    height: 360px;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    @media (max-width: 768px) {
        margin: 1rem 0;
        width: calc(100% - 5rem);
        height: 280px;
    }
`

const Information = styled.div`
    position: relative;
    top: -10%;
    display: flex;
    height: 300px;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`