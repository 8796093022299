import { Box, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import styled from 'styled-components';
import Card from './components/Card';

const WhyChooseUs = () => {

    const isMobile = useMediaQuery('(max-width:768px)');

    return (
        <Content id="Beneficios">
            <Information>
                <Typography color={'secondary'} fontSize={isMobile ? 32 : 48} fontWeight={'bold'}>Por qué elegir</Typography>
                <br/>
                <Box
                    component="img"
                    sx={{
                        height: isMobile ? 24 : 44,
                        // borderRadius: '50%',
                        // objectFit: 'cover',
                        // boxShadow: 3,
                    }}
                    alt={'Deel Markets'}
                    src={'assets/images/logo_h_2.png'}
                />
            </Information>
            <Cards>
                <Card
                    title="Seguridad"
                    description="Tu seguridad y privacidad son nuestras prioridades."
                    image="assets/images/security.png"
                />
                <Card
                    title="Velocidad"
                    description="Ejecución rápida y transparente. Retiros en menos de 24hs."
                    image="assets/images/speed.png"
                />
                <Card
                    title="Profesionalismo"
                    description="Conta con el soporte más personalizado de la industria."
                    image="assets/images/auto.png"
                />
            </Cards>
        </Content>
    )
}

export default WhyChooseUs

const Content = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 56px;
    height: 800px;
    background: radial-gradient(80.44% 80.44% at 50% 50%, #00FFE0 0%, #0B1119 100%);
    @media (max-width: 768px) {
        height: auto;
        padding: 2rem 0;
    }
`

const Cards = styled.div`
    display: flex;
    gap: 16px;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
`

const Information = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
    @media (max-width: 768px) {
        margin-bottom: 0;
    }
`