import { Link, Container, Button, IconButton, Box, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import styled from 'styled-components';

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const isMobile = useMediaQuery('(max-width:768px)');

    const styles = isMobile ? {
        height: 18,
        position: "absolute",
        top: '27px',
        left: '18px'
    } : {
        display: 'none',
    }

    const styles2 = isMobile ? {
        height: 18,
        position: "absolute",
        top: '27px',
        left: '18px'
    } : {
        display: 'flex',
    }

    return (
        <Content id="Home">
            <ContainerCustom>
                <Box
                    component="img"
                    sx={styles}
                    alt={'Deel Markets'}
                    src={'assets/images/logo_h.png'}
                />
                <HamburgerButton onClick={toggleMenu}>
                    {menuOpen ? <CloseIcon color={'secondary'} /> : <MenuIcon color={'secondary'} />}
                </HamburgerButton>
                <List menuOpen={menuOpen}>


                    <Items menuOpen={menuOpen}>
                        <Box
                            component="img"
                            sx={styles2}
                            alt={'Deel Markets'}
                            src={'assets/images/logo_h.png'}
                        />
                        <br />
                        <Link href='#Como-funciona' color={'secondary'} underline="none" onClick={() => toggleMenu()}>Cómo funciona</Link>
                        <Link href='#Tipo-de-cuenta' color={'secondary'} underline="none" onClick={() => toggleMenu()}>Tipo de cuenta</Link>
                        <Link href='#Beneficios' color={'secondary'} underline="none" onClick={() => toggleMenu()}>Beneficios</Link>
                        <Link href='#cTraders' color={'secondary'} underline="none" onClick={() => toggleMenu()}>cTraders</Link>
                    </Items>
                    <Buttons menuOpen={menuOpen}>
                        <Button variant='outlined'>INICIAR SESIÓN</Button>
                        <Button variant='contained'>REGISTRARSE</Button>
                    </Buttons>
                </List>
            </ContainerCustom>
        </Content>
    );
};

export default Navbar;

const Content = styled.nav`
    width: 100%;
    height: 90px;
    background-color: #0B1119;
    position: relative;
    z-index: 9999 !important;
    @media (max-width: 768px) {
        height: 70px;
    }
`;

const ContainerCustom = styled(Container)`
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    position: relative;
    @media (max-width: 768px) {
        justify-content: flex-end;
        gap: 12px;
    }
`;

const Items = styled.div`
    display: flex;
    gap: 16px;
    /* width: 100%; */
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
        font-size: 18px;
    }
`

const List = styled.div`
    display: flex;
    gap: 16px;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 74%;
        height: 100%;
        background-color: #0B1119;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        transform: ${props => (props.menuOpen ? 'translateX(0)' : 'translateX(-100%)')};
        transition: transform 0.3s ease-in-out;
        z-index: 1000;
    }
`;

const Buttons = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        width: 100% !important;
        margin-top: 1rem; /* Ajusta el espacio superior en móviles si es necesario */
        button{
            width: 100%;
        }
    }
`;

const HamburgerButton = styled(IconButton)`
    display: none !important;
    @media (max-width: 768px) {
        display: block !important;
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 1001;
    }
`;
