import { Box, Container, Link, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const CTraders = () => {

    const isMobile = useMediaQuery('(max-width:768px)');

    return (
        <Content id="cTraders">
            <BG src='/assets/images/bg-ctraders.png' />
            <ContainerInfo>
                <Information>
                    <Typography fontSize={isMobile ? 32 : 48} fontWeight={'bold'}>Por qué elegimos</Typography>
                    <Box
                        component="img"
                        sx={{
                            height: 'auto',
                            width: '120px',
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            display: isMobile ? 'none' : 'flex'
                        }}
                        alt={'cTraders'}
                        src='/assets/images/ctrader_round.png'
                    />
                    <Box
                        component="img"
                        sx={{
                            height: 'auto',
                            width: isMobile ? '160px' : '120px',
                            marginBottom: '16px'
                        }}
                        alt={'Mobiles cTraders'}
                        src='/assets/images/ctrader_logo.png'
                    />
                    <Typography fontSize={isMobile ? 16 : 20} marginBottom={'16px'}>La nueva generación de traders utiliza <Typography fontSize={isMobile ? 16 : 20} component={'span'} fontWeight={'bold'}>CTrader</Typography>,
                        la plataforma de tecnología que cuenta con una interfaz intuitiva, moderna, accesible y eficiente.</Typography>
                    <Typography fontSize={isMobile ? 16 : 20} marginBottom={'16px'}>
                        Posee una ejecución de órdenes rápida y precisa, proporcionando un entorno de desarrollo más intuitivo para algoritmos y trading automatizado.
                    </Typography>
                    <Typography fontSize={isMobile ? 16 : 20} marginBottom={'16px'}>
                        Sus avanzados gráficos son altamente flexibles
                        y personalizables.
                    </Typography>
                    <Buttons>
                        <Link href="">
                            <Box
                                component="img"
                                sx={{
                                    height: isMobile ? 44 : 66,
                                    width: 'auto',
                                }}
                                alt={'Mobiles cTraders'}
                                src='/assets/images/google-play.png'
                            />
                        </Link>
                        <Link href="">
                            <Box
                                component="img"
                                sx={{
                                    height: isMobile ? 44 : 66,
                                    width: 'auto',
                                }}
                                alt={'Mobiles cTraders'}
                                src='/assets/images/applestore.png'
                            />
                        </Link>
                    </Buttons>
                </Information>
                <ContentImage>
                    <Box
                        component="img"
                        sx={{
                            height: 620,
                            width: 'auto',
                        }}
                        alt={'Mobiles cTraders'}
                        src='/assets/images/mobiles.png'
                    />
                </ContentImage>
            </ContainerInfo>
        </Content>
    )
}

export default CTraders

const Content = styled.div`
    display: flex;
    background-color: white;
    width: 100%;
    height: 770px;
    position: relative;
    @media (max-width: 768px) {
        width: calc(100% - 2rem);
        padding: 1rem 0;
        height: auto;
        justify-content: center;
        align-items: center;
    }
`

const BG = styled.img`
    position: absolute;
    right: 0;
    width: auto;
    height: 100%;
    @media (max-width: 768px) {
        display: none;
    }
`

const ContainerInfo = styled(Container)`
    width: 100%;
    height: 100% !important;
    display: grid !important;
    justify-self: center;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    z-index: 2;
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        text-align: center;
    }
`

const Information = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
        justify-content: flex-start;
        align-items: flex-start;
        text-align: start;
    }
`

const ContentImage = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        display: none;
    }
`

const Buttons = styled.div`
    display: flex;
    gap: 12px;
    @media (max-width: 768px) {
        justify-content: flex-start;
        align-items: flex-start;
        text-align: start;
    }
`