import { Container } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import Card from './components/Card'
import Information from './components/Information'

const AccountType = () => {

    const standard = [
        {
            numbers: '1.1',
            currency: 'EUR/USD'
        },
        {
            numbers: '1.1',
            currency: 'USD/JPY'
        },
        {
            numbers: '1.3',
            currency: 'EUR/JPY'
        },
        {
            numbers: '1.2',
            currency: 'GBP/USD'
        },
        {
            numbers: '1.2',
            currency: 'EUR/GBP'
        },
        {
            numbers: '3.4',
            currency: 'XAU/USD'
        },
    ]

    const raw = [
        {
            numbers: '0.0',
            currency: 'EUR/USD'
        },
        {
            numbers: '0.0',
            currency: 'USD/JPY'
        },
        {
            numbers: '0.1',
            currency: 'EUR/JPY'
        },
        {
            numbers: '0.2',
            currency: 'GBP/USD'
        },
        {
            numbers: '0.2',
            currency: 'EUR/GBP'
        },
        {
            numbers: '0.9',
            currency: 'XAU/USD'
        },
    ]

    return (
        <Content id="Tipo-de-cuenta">
            <ContainerFake>
                <Cards>
                    <Card title={'Estándar'} subtitle={'Sin comisión'} currencyArray={standard} />
                    <Card title={'Raw'} subtitle={'Baja comisión $2,5/lado'} currencyArray={raw} />
                    <Information />
                </Cards>
            </ContainerFake>
        </Content>
    )
}

export default AccountType


const Content = styled.div`
    background: radial-gradient(37.12% 55.3% at 38.87% 50%, #00322C 2.34%, #0B1119 100%);
    height: 736px;
    width: 100%;
    @media (max-width: 768px) {
        flex-direction: column;
        height: auto;
        padding: auto;
        gap: 12px;
        padding-bottom: 2rem;
    }
`

const ContainerFake = styled(Container)`
    height: 100%;
`

const Cards = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column-reverse;
        height: auto;
        padding: auto;
        gap: 12px;
        padding: 1rem 0;
    }
`