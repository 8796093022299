import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: "#00FFE0",
            contrastText: '#0B1119'
        },
        secondary: {
            main: "#fff",
            contrastText: '#fff'
        },
        info: {
            main: "#0B1119",
            contrastText: '#00FFE0'
        },
    },
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    textTransform: 'none',
                    borderRadius: '8px',
                    border: '1.5px solid',
                    boxShadow: 'none',
                    fontWeight: 'bold'
                },
            },
        },
    },
});
