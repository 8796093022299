import { Box, Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const Card = (props) => {

    const { image, title, description, button, canArrow = true } = props

    return (
        <Content canArrow={canArrow}>
            <Box
                component="img"
                sx={{
                    height: 48,
                    width: 48,
                }}
                alt={title}
                src={image}
            />
            <Typography fontSize={20} fontWeight={600} color={'info'}>{title}</Typography>
            <Typography color={'GrayText'}>{description}</Typography>
            {button && <>
                <br />
                {button}
            </>}
        </Content>
    )
}

export default Card

const Content = styled.div`
    border: 1px solid #00FFE0;
    border-radius: 25px;
    min-width: 200px;
    width: 100%;
    height: fit-content;
    min-height: 150px;
    position: relative;
    padding: 1rem;
    ${p => p.canArrow && `
        &::before{
        content: '';
        height: 20px;
        width: 20px;
        border: 1px solid #00FFE0;
        position: absolute;
        right: -12px;
        top: calc(85px - 10px);
        transform: rotate(45deg);
        }
        &::after{
            content: '';
            height: 30px;
            width: 30px;
            background-color: #EEF8FF;
            position: absolute;
            right: -9px;
            top: calc(85px - 14px);
            transform: rotate(45deg);
        }
    `}
    @media (max-width: 768px) {
        min-width: auto;
        max-width: 100%;
        width: 220px;
        padding: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: auto;
        ${p => p.canArrow && `
        &::before{
            content: '';
            display: none;
        }
        &::after{
            content: '';
            display: none;
        }
    `}
    }
`